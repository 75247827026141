<!--档案管理系统-->
<template>
  <div class="record-container">
    <new-cloude-header/>
    <headerBanner :content="bannerContent" title="档案管理系统" @btnClick="bannerClick"
                  imgStyle="width:463px;height:387px" :imgSrc="`${require('@/assets/img/solution/档案管理系统.png')}`"
                  contentStyle="width:90%"/>
    <!--传统档案管理面临的痛点-->
    <div class="module-1">
      <div class="module-content">
        <p class="module-title">传统档案管理面临的痛点</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="list of dataList">
              <el-col :span="12">
                <div class="col-content">
                  <p class="default-title">{{ list.title }}</p>
                  <p class="introduce">{{ list.introduce }}</p>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <!--档案管理六大核心能力-->
    <div class="module-2">
      <div class="module-content">
        <p class="module-title">档案管理六大核心能力</p>
        <div class="module--content">
          <div class="module-left">
            <div class="module-li">
              <p class="title">档案门类</p>
              <p class="content">可以针对已归档档案进行维护操作，如文书档案、声像档案、实物档案、科技档案和专业档案等。</p>
            </div>
            <div class="module-li li-2">
              <p class="title">档案查询</p>
              <p class="content">可以针对档案进行利用，可以借阅及查看档案，包括档案查询、共享查询和库房查询。</p>
            </div>
            <div class="module-li li-3">
              <p class="title">档案统计</p>
              <p class="content">对电子档案利用和室藏情况进行统计。可按年度、全宗号等要素对已归档电子文件、电子档案利用情况进行统计。可按档案门类、在库量、销毁量、移交量等要素对电子档案室藏量进行统计</p>
            </div>
          </div>
          <div class="module-center"/>
          <div class="module-right">
            <div class="module-li">
              <p class="title">档案工作台</p>
              <p class="content">普通用户包括档案管理员外的其他人员，包括科员，主任科员，领导等，主要作为普通用户，对档案进行查阅、借阅、搜索和审批，可以根据不同角色配置档案工作台门户。</p>
            </div>
            <div class="module-li li-2">
              <p class="title">文件归档</p>
              <p class="content">包括公文管理 (发文、收文、签报)、事务审批、外部信息录入、接口导入、离线包上传等。</p>
            </div>
            <div class="module-li li-3">
              <p class="title">归档管理</p>
              <p class="content">是针对预归档文件提交到档案室的相关处理工作，包括按年度归档、季度归档、月度归档进行整理，入盒等操作。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--档案管理全生命周期管理解决方案-->
    <div class="module-3">
      <div class="module-content">
        <p class="module-title">档案管理全生命周期管理解决方案</p>
        <div class="module-img"/>
      </div>
    </div>
    <!--帮企数智云CMS的优势-->
    <div class="module-4">
      <div class="module-content">
        <p class="module-title">帮企数智云档案管理优势</p>
        <div class="module-col">
          <el-row :gutter="20">
            <template v-for="list of dataList2">
              <el-col :span="12">
                <div class="col-content">
                  <div class="col-img"
                       :style="'background: url('+require('@/assets/img/solution/'+list.icon)+') no-repeat'"/>
                  <div class="col-right">
                    <p class="default-title">{{ list.title }}</p>
                    <p class="introduce">{{ list.introduce }}</p>
                  </div>
                </div>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'档案管理系统'"/>
    <new-cloude-footer/>
  </div>
</template>
<script>
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import headerBanner from "@/components/assembly/headerBanner";
import applyTrial from "@/components/assembly/applyTrial";
export default {
  name: "record",
  components: {NewCloudeFooter, NewCloudeHeader, headerBanner,applyTrial},
  data() {
    return {
      bannerContent:'档案管理系统，为归档单位提供先进的数字档案室（馆）信息化解决方案，实现档案数字化、信息化、标准化和智慧化。',
      dataList:[
        {title: '没有引入新一代信息化技术', introduce: '比如扫描仪未和系统联通，需要人工录入档案元数据、扫描件还需要单独数字化加工处理等，操作繁琐，用户体验不好。'},
        {title: '归档延迟、遗漏归档或归档属性不准确问题', introduce: '不能实时掌握待归档、未归档信息，不能对归档属性进行准确性判断，导致档案管理部门无法监督和有效管理。'},
        {title: '没有与办公系统或业务系统有效协同', introduce: '需要再次数字化处理、人工编目、人工录入、人工统计，耗时耗力且容易出错。'},
        {title: '没有档案管理系统支撑或采用单机版档案管理软件', introduce: '需要档案部门人工处理、工作效率低、不能跨单位、跨部门共用。'},
      ],
      dataList2:[
        {title: '共享共用，多级并用', introduce: '满足多层级多单位共用，公开档案互通互用；也支撑横向和纵向多层级独立使用，管控数据互不影响。', icon: '共享共用，多级并用.png'},
        {title: '灵活配置，操作简单', introduce: '能实现档案门类、结构树、列表、搜索项、导出项等自定义功能，灵活配置，使用强大的自定义平台和流程引擎，支持表单、字段、流程审批自定义，符合业务变化的需要。', icon: '灵活配置，操作简单.png'},
        {title: '标准化、规范化', introduce: '严格按国家及相关地区的档案相关建设标准规范而建设，提供标准的接口规范，实现第三方系统在线或离线归档，也能实现与档案馆系统快速、准确地移交处理。', icon: '标准化规范化.png'},
        {title: '智能化、自动化', introduce: '减少人工归档及著录工作，实现扫描后自动归档、自动编目、自动合并转版、OCR识别、文字提取等智能化场景应用。', icon: '智能化自动化.png'},
      ]
    }
  },
  methods:{
    bannerClick(){
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.record-container{
  background: #FFFFFF;
  min-width: 1600px;
  .module-1 {
    background: #FFFFFF;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .module-col {
        .col-content {
          background: #F7F8FF;
          padding: 30px 20px;
          margin-bottom: 20px;
          height: 80px;
          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            padding-left: 10px;
            border-left: 4px solid #1935DE;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }
          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
          }
        }
      }
    }
  }
  .module-2 {
    background: #F7F8FE;

    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;

      .module--content {
        display: flex;
        justify-content: space-between;

        .module-center {
          width: 483px;
          height: 357px;
          background: url("~@/assets/img/solution/合同管理六大核心能力.png");
          background-size: 100% 100%;
        }

        .module-left {
          width: 350px;
          margin-top: -10px;

          .module-li {
            text-align: end;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
          }

          .li-2 {
            margin-top: 55px;
          }

          .li-3 {
            margin-top: 90px;
          }
        }

        .module-right {
          width: 350px;
          margin-top: -10px;

          .module-li {
            text-align: left;

            .title {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 18px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }

            .content {
              margin-block-start: 0px;
              margin-block-end: 0px;
              font-size: 16px;
              color: #333333;
              font-family: 'SourceHanSansCN';
            }
          }

          .li-2 {
            margin-top: 30px;
          }

          .li-3 {
            margin-top: 93px;
          }
        }
      }
    }
  }
  .module-3 {
    background: #FFFFFF;
    height: 713px;
    width: 100%;
    .module-content {
      width: 1623px;
      margin: auto;
      padding: 60px 0px 0px;
      .module-img {
        width: 1623px;
        height: 468px;
        background: url("~@/assets/img/solution/档案管理全生命周期管理解决方案.png") no-repeat;
        background-size: 100% 100%;
        float: right;
      }
    }
  }
  .module-4 {
    background: #F7F8FE;
    height: 497px;
    width: 100%;
    padding-bottom: 60px;
    .module-content {
      width: 1200px;
      margin: auto;
      padding: 60px 0px;
      .col-content {
        background: #FFFFFF;
        padding: 30px 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
        height: 105px;
        .col-img {
          width: 40px;
          height: 43px;
          background-size: 100% 100% !important;
        }
        .col-right{
          width: 450px;
          margin-left: 20px;
          .default-title {
            font-size: 18px;
            font-family: 'SourceHanSansCN';
            color: #333333;
            margin-block-end: 15px;
            margin-block-start: 0px;
            line-height: 18px;
          }

          .introduce {
            font-size: 16px;
            font-family: "SourceHanSansCN";
            color: #333333;
            margin-block-end: 0px;
            margin-block-start: 0px;
          }
        }
      }
    }
  }
  .module-title {
    font-family: 'SourceHanSansCN';
    color: #333333;
    font-size: 30px;
    margin-block-start: 0px;
    margin-block-end: 40px;
    text-align: center;
  }
}
</style>
